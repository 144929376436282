/**
 * Created by Reda on 23.10.2024
 */

var OctopusHuntFooter = cc.Node.extend({
    ctor: function (octopusHunt) {
        this._super();
        this.octopusHunt = octopusHunt;

        this.createRewardsArea();
        this.createButton();
        this.updateState();
        this.updateSize();
    },

    createRewardsArea: function () {
        var styles = cleverapps.styles.OctopusHuntFooter.rewardsArea;
        var rewardsArea = this.rewardsArea = new cc.Scale9Sprite(bundles.octopus_hunt.frames.rewards_list_bg_png);
        rewardsArea.setContentSize2(styles.size);

        var guide = this.guide = new cleverapps.UI.HelpButton(function () {
            var scene = cleverapps.scenes.getRunningScene();
            scene.openGuideWindow();
        });
        rewardsArea.addChild(guide);
        guide.setPositionRound(styles.guide);

        this.addChild(rewardsArea);
    },

    createButton: function () {
        var styles = cleverapps.styles.OctopusHuntFooter.button;

        var button = this.quitButton = new cleverapps.UI.Button({
            text: "OctopusHuntScene.quitButton",
            width: styles.width,
            height: styles.height,
            onClicked: function () {
                cleverapps.focusManager.display({
                    focus: "ConfirmExitWindow",
                    stack: true,
                    action: function (f) {
                        new ConfirmExitWindow({
                            action: function () {
                                f();
                                this.octopusHunt.endGame();
                            }.bind(this),
                            text: Messages.get("OctopusHuntProlongationWindow.leftStages", {
                                stages: this.octopusHunt.calcStagesBeforeBonus()
                            }),
                            image: bundles.octopus_hunt.frames.sea_window_ship_png,
                            padding: {
                                bottom: 0
                            }
                        });

                        cleverapps.focusManager.onceNoWindowsListener = f;
                    }.bind(this)
                });
            }.bind(this)
        });
        button.setContentSize2(styles);

        this.addChild(button);
    },

    calcRewardsColumns: function (amount) {
        if (cleverapps.resolution.mode !== cleverapps.WideMode.VERTICAL) {
            return amount;
        }

        if (amount <= 3) {
            return amount;
        }

        return Math.ceil(amount / 2);
    },

    updateState: function () {
        var styles = cleverapps.styles.OctopusHuntFooter;

        if (this.rewardsArea.rewardsList) {
            this.rewardsArea.rewardsList.removeFromParent();
            this.rewardsArea.rewardsList = undefined;
        }

        var rewardsAmount = OctopusHunt.countRewards(this.octopusHunt.rewards);
        this.rewardsArea.rewardsList = new RewardsListComponent(new RewardsList(this.octopusHunt.rewards), {
            fitToBox: {
                width: this.rewardsArea.width - styles.rewardsArea.padding,
                height: this.rewardsArea.height - styles.rewardsArea.padding
            },
            columns: this.calcRewardsColumns(rewardsAmount),
            font: cleverapps.styles.FONTS.WHITE_TEXT,
            textDirection: cleverapps.UI.HORIZONTAL,
            small: true,
            noShowControls: true
        });
        this.rewardsArea.addChild(this.rewardsArea.rewardsList);
        this.rewardsArea.rewardsList.setPositionRound(styles.rewardsArea.rewardList);
        rewardsAmount > 0 ? this.quitButton.enable() : this.quitButton.disable();
    },

    getRewardTarget: function (reward) {
        var target = this.rewardsArea;
        for (var i = 0; i < this.rewardsArea.rewardsList.targets.length; i++) {
            if (this.rewardsArea.rewardsList.targets[i].reward.isEqual(reward)) {
                target = this.rewardsArea.rewardsList.targets[i];
                break;
            }
        }
        if (!target.aim) {
            cleverapps.aims.registerTarget("octopusHuntFieldReward", target);
        }
        return target;
    },

    updateSize: function () {
        var styles = cleverapps.styles.OctopusHuntFooter;
        if (this.rewardsArea.rewardsList) {
            var rewardsAmount = OctopusHunt.countRewards(this.octopusHunt.rewards);
            this.rewardsArea.rewardsList.content.options.columns = this.calcRewardsColumns(rewardsAmount);
            this.rewardsArea.rewardsList.content.options.fitToBox = {
                width: this.rewardsArea.width - styles.rewardsArea.padding,
                height: this.rewardsArea.height - styles.rewardsArea.padding
            };
            this.rewardsArea.rewardsList.content.reshape();
        }
        this.rewardsArea.setPositionRound(styles.rewardsArea.position);

        this.quitButton.setPositionRound(styles.button.position);
        this.rewardsArea.setContentSize2(styles.rewardsArea.size);
        var scale = Math.min(1, cleverapps.scenes.getRunningScene().width / (this.rewardsArea.width + styles.scenePadding));
        this.setScale(scale);
    }
});

cleverapps.styles.OctopusHuntFooter = {
    rewardsArea: {
        position: [
            {
                x: { align: "center", dx: 0 },
                y: { align: "center", dy: 60 }
            },
            {
                x: { align: "center", dx: 0 },
                y: { align: "center", dy: 100 }
            },
            {
                x: { align: "center", dx: 50 },
                y: { align: "center", dy: 0 }
            }
        ],
        size: [
            { width: 600, height: 160 },
            { width: 820, height: 120 },
            { width: 820, height: 120 }
        ],
        rewardList: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 5 }
        },
        padding: 40,
        guide: {
            x: { align: "left", dx: -70 },
            y: { align: "center", dy: 0 }
        }
    },
    button: {
        position: [
            {
                x: { align: "center", dx: 0 },
                y: { align: "center", dy: -90 }
            },
            {
                x: { align: "center", dx: 0 },
                y: { align: "center", dy: -30 }
            },
            {
                x: { align: "center", dx: 630 },
                y: { align: "center", dy: 6 }
            }
        ],
        padding: {
            top: 0,
            bottom: 10,
            left: 5,
            right: 0
        },
        offset: 120,
        width: 320,
        height: 110
    },
    scenePadding: 220
};