/**
 * Created by Reda on 13.10.2024
 */

var OctopusHuntMissionLogic = function (mission) {
    this.mission = mission;
    this.mission.result = this.mission.result || 0;
    this.octopusHunt = new OctopusHunt(mission);
};

OctopusHuntMissionLogic.prototype.getLevelReward = function () {
    return 0;
};