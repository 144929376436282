/**
 * Created by Reda on 21.10.2024
 */

OctopusHuntMarkView = cc.Node.extend({
    ctor: function (stageType, number, alternate, last, current) {
        var styles = cleverapps.styles.OctopusHuntMarkView;
        this.alternate = alternate;
        this.number = number;
        this.stageType = stageType;
        this._super();
        this.setContentSize2(styles);
        var mark = this.mark = new TextWithIcon(number + "$", {
            icons: {
                "$": this.chooseMarkImage()
            },
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
        mark.setAnchorPoint(0.5, 0);
        this.addChild(mark);
        if (!current) {
            var numberBackground = new cc.Sprite(bundles.octopus_hunt.frames.mark_number_background_png);
            this.mark.addChild(numberBackground, -1);
            numberBackground.setPositionRound(styles.numberBackground);
        }
        this.mark.setPositionRound(
            this.alternate
                ? styles.mark.alternate
                : styles.mark.normal
        );
        if (!last) {
            this.createContent();
        } else {
            this.dots = [];
        }
    },
    setActive: function () {
        this.mark.setString(this.number + "$");
    },
    moveToNextAnimation: function (duration) {
        var styles = cleverapps.styles.OctopusHuntMarkView;
        this.stageType = OctopusHuntGame.STAGE_TYPES.PREVIOUS;
        this.mark.customIcons = {
            "$": this.chooseMarkImage()
        };
        this.mark.setString(this.number + "$");
        var numberBackground = new cc.Sprite(bundles.octopus_hunt.frames.mark_number_background_png);
        this.mark.addChild(numberBackground, -1);
        numberBackground.setPositionRound(styles.numberBackground);
        for (var i = 0; i < this.dots.length; i++) {
            cleverapps.timeouts.setTimeout(function () {
                var position = this.self.dots[this.index].getPosition();
                this.self.dots[this.index].removeFromParent();
                var dot = new cc.Sprite(bundles.octopus_hunt.frames.path_dot_off_png);
                this.self.addChild(dot);
                dot.setPositionRound(position);
                this.self.dots[this.index] = dot;
            }.bind({
                index: i,
                self: this
            }), (i + 1) * duration / this.dots.length);
        }
    },
    getShipPosition: function (ship) {
        var offset = cleverapps.styles.OctopusHuntMarkView.ship.offset;
        var position = ship.getParent().convertToNodeSpace(
            this.convertToWorldSpace(this.mark.getPosition())
        );
        position.x += offset;
        return position;
    },
    chooseMarkImage: function () {
        var image = bundles.octopus_hunt.frames.mark_silver_png;
        if (this.stageType === OctopusHuntGame.STAGE_TYPES.PREVIOUS) {
            image = bundles.octopus_hunt.frames.mark_off_png;
        } else if (this.stageType === OctopusHuntGame.STAGE_TYPES.BONUS) {
            image = bundles.octopus_hunt.frames.mark_gold_png;
        }
        return image;
    },
    createContent: function () {
        var numberOfDots = 3;
        this.dots = [];
        for (var i = 1; i <= numberOfDots; i++) {
            var dot = new cc.Sprite(
                this.stageType === OctopusHuntGame.STAGE_TYPES.PREVIOUS
                    ? bundles.octopus_hunt.frames.path_dot_off_png
                    : bundles.octopus_hunt.frames.path_dot_on_png
            );
            this.addChild(dot);
            this.dots.push(dot);
        }
    },
    updatePosition: function (width) {
        this.setContentSize(width, this.height);
        var numberOfDots = 3;
        for (var i = 0; i < this.dots.length; i++) {
            var xPos = this.mark.width + (this.width - this.mark.width) * (i + 1) / (numberOfDots + 1);
            this.dots[i].setPositionRound({
                x: xPos,
                y: (this.alternate ? 1 : 0) * this.height + (this.alternate ? -1 : 1) * this.height * (i + 1) / (numberOfDots + 1)
            });
        }
    }
});

cleverapps.styles.OctopusHuntMarkView = {
    width: 400,
    height: 100,
    margin: 15,
    mark: {
        normal: {
            x: {
                align: "left"
            },
            y: {
                align: "bottom"
            }
        },
        alternate: {
            x: {
                align: "left"
            },
            y: {
                align: "top",
                dy: 80
            }
        }
    },
    numberBackground: {
        x: {
            align: "center"
        },
        y: {
            align: "top",
            dy: 5
        }
    },
    ship: {
        offset: 25
    }
};