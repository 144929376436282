/**
 * Created by Reda on 15.10.2024
 */

var OctopusHuntBarrel = function (game) {
    cleverapps.EventEmitter.call(this);
    this.game = game;
    this.opened = false;
    this.game.on("submerge", function () {
        this.trigger("submerge");
    }.bind(this));
};

OctopusHuntBarrel.prototype = Object.create(cleverapps.EventEmitter.prototype);
OctopusHuntBarrel.prototype.constructor = OctopusHuntBarrel;

OctopusHuntBarrel.prototype.showReward = function (reward) {
    this.trigger("showReward", reward.octopus ? undefined : reward);
};

OctopusHuntBarrel.prototype.collectReward = function (reward) {
    this.opened = true;
    this.trigger("collectReward", reward);
};

OctopusHuntBarrel.prototype.onCollect = function (reward) {
    this.game.receiveStageRewards(reward);
    this.game.octopusHunt.updateRewards();
};

OctopusHuntBarrel.prototype.endRound = function (reward, f) {
    this.game.endRound(reward, f);
};

OctopusHuntBarrel.prototype.close = function () {
    if (!this.opened) {
        return;
    }
    this.opened = false;

    this.trigger("close");
};

OctopusHuntBarrel.prototype.onClick = function () {
    if (this.game.isRoundRunning()) {
        var reward = this.game.chooseRandomReward();
        this.collectReward(reward);
    }
};