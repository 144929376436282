/**
 * Created by Reda on 14.10.2024
 */

var OctopusHuntPath = cc.Node.extend({
    ctor: function (scene, octopusHunt) {
        this._super();
        this.scene = scene;
        this.semaphore = true;
        this.octopusHunt = octopusHunt;
        this.createBoat();
        this.createMarks();
        this.setAnchorPoint(0, 0);
        this.updatePosition();
    },
    toNextStage: function () {
        if (this.nextStageAction && this.shipAction) {
            this.nextStageAction.stop();
            this.shipAction.stop();
        }
        var duration = 0.7;
        var position = this.markViews[this.octopusHunt.getCurrentStage()].getShipPosition(this.ship);
        this.nextStageAction = this.runAction(new cc.Sequence(
            new cc.CallFunc(function () {
                this.semaphore = false;
                this.ship.replaceParentSamePlace(this);
                this.shipAction = this.ship.runAction(new cc.Sequence(
                    new cc.CallFunc(function () {
                        cleverapps.audio.playSound(bundles.octopus_hunt.urls.ship_move_effect);
                        this.ship.setAnimationAndIdleAfter("jump", "idle");
                    }.bind(this)),
                    new cc.MoveBy(
                        duration,
                        this.markViews[this.octopusHunt.getCurrentStage() - 1].width,
                        position.y - this.ship.y
                    )
                ));
                this.markViews[this.octopusHunt.getCurrentStage() - 1].moveToNextAnimation(duration);
                this.markViews[this.octopusHunt.getCurrentStage()].setActive();
            }.bind(this)),
            new cc.MoveBy(duration, -this.markViews[this.octopusHunt.getCurrentStage()].width, 0),
            new cc.DelayTime(0.1),
            new cc.CallFunc(function () {
                this.semaphore = true;
                this.updatePosition();
            }.bind(this))
        ));
    },

    createMarks: function () {
        var markViews = this.markViews = [];
        for (var i = 0; i < OctopusHunt.STAGES.length; i++) {
            var mark = new OctopusHuntMarkView(this.octopusHunt.getStageType(i), i + 1, i % 2, i === OctopusHunt.STAGES.length - 1, i === this.octopusHunt.getCurrentStage());
            markViews.push(mark);
            this.addChild(mark, OctopusHunt.STAGES.length - i);
        }
        this.updatePosition();
    },

    createBoat: function () {
        var ship = this.ship = new cleverapps.Spine(bundles.octopus_hunt.jsons.ship_json);
        ship.setAnchorPoint(0.5, 0);
        this.addChild(ship, OctopusHunt.STAGES.length + 1);
        ship.setIdle("idle");
    },

    updateProgress: function () {
        var step = this.width / OctopusHunt.VISIBLE_MARKS[cleverapps.resolution.mode];
        for (var i = 0; i < this.markViews.length; i++) {
            this.markViews[i].updatePosition(step);
            this.markViews[i].setPositionRound({
                x: (step) * i,
                y: this.markViews[i].getPosition().y
            });
        }
    },

    updatePosition: function () {
        var styles = cleverapps.styles.OctopusHuntPath;
        if (this.semaphore) {
            this.setContentSize2(
                styles.size.width,
                styles.size.height
            );
            this.updateProgress();
            var position = this.markViews[this.octopusHunt.getCurrentStage()].getShipPosition(this.ship);
            this.ship.setPositionRound(position);
            position.x = styles.padding[cleverapps.resolution.mode] - position.x;
            this.setPositionRound(position.x, this.y);
        }
    }
});

cleverapps.styles.OctopusHuntPath = {
    padding: [
        250,
        530,
        830
    ],
    size: {
        width: [
            800, 1400, 2400
        ],
        height: 150
    },
    marks: {
        dy: 50,
        margin: 15,
        numberBackground: {
            x: {
                align: "center"
            },
            y: {
                align: "top",
                dy: 5
            }
        }
    }
};