/**
 * Created by Reda on 13.10.2024
 */

var OctopusHunt = function (mission) {
    cleverapps.EventEmitter.call(this);

    this.mission = mission;
    this.rewards = {};
    this.octopus = false;
    this.prolongation = new Prolongation();

    if (mission.details) {
        this.rewards = mission.details.rewards || {};
        this.octopus = mission.details.octopus;
        if (mission.details.endGame || this.isFinished()) {
            this.mission.complete();
        }
    }
};

OctopusHunt.prototype = Object.create(cleverapps.EventEmitter.prototype);
OctopusHunt.prototype.constructor = OctopusHunt;

OctopusHunt.prototype.getCurrentStage = function () {
    return this.mission.result;
};

OctopusHunt.prototype.isFinished = function () {
    return this.mission.result >= OctopusHunt.STAGES.length;
};

OctopusHunt.prototype.isLost = function () {
    return this.octopus;
};

OctopusHunt.prototype.updateRewards = function () {
    this.trigger("updateRewards");
};

OctopusHunt.prototype.toNextStage = function () {
    this.mission.increaseResult(1, {
        rewards: this.rewards,
        octopus: false
    });

    if (this.isFinished()) {
        this.endGame();
    } else {
        this.trigger("updateState");
    }
};

OctopusHunt.prototype.handleOctopus = function () {
    this.mission.increaseResult(0, {
        rewards: this.rewards,
        octopus: true
    });
    this.octopus = true;
};

OctopusHunt.prototype.prolong = function () {
    this.mission.increaseResult(0, {
        rewards: this.rewards,
        octopus: false
    });
    this.octopus = false;
};

OctopusHunt.prototype.endGame = function () {
    this.mission.complete();
    this.mission.increaseResult(0, {
        rewards: this.rewards,
        octopus: false,
        endGame: true
    });
    this.trigger("endGame");
};

OctopusHunt.prototype.getStageType = function (stage) {
    if (stage < this.getCurrentStage()) {
        return OctopusHuntGame.STAGE_TYPES.PREVIOUS;
    }
    if (this.isBonusStage(stage)) {
        return OctopusHuntGame.STAGE_TYPES.BONUS;
    }
    return OctopusHuntGame.STAGE_TYPES.NORMAL;
};

OctopusHunt.prototype.isBonusStage = function (stage) {
    return stage ? OctopusHunt.STAGES[stage] : OctopusHunt.STAGES[this.getCurrentStage()];
};

OctopusHunt.prototype.calcStagesBeforeBonus = function () {
    var currentStage = this.getCurrentStage();

    for (var stage = currentStage + 1; stage <= OctopusHunt.STAGES.length; stage++) {
        if (this.isBonusStage(stage)) {
            return stage - currentStage;
        }
    }

    return OctopusHunt.STAGES.length - currentStage + 1;
};

OctopusHunt.prototype.addReward = function (reward) {
    if (!reward.octopus) {
        this.rewards = RewardsList.sumRewards(this.rewards, reward);
    }
};

OctopusHunt.prototype.createRewardPool = function () {
    return OctopusHunt.REWARD_POOL.slice().map(function (reward, index) {
        var probability = reward.probability || 1;
        delete reward.probability;

        reward.id = index;

        return cleverapps.arrayFill(probability, reward);
    }).flat();
};

OctopusHunt.countRewards = function (reward) {
    var result = 0;
    var properties = Object.keys(reward);
    for (var i = 0; i < properties.length; i++) {
        if (typeof reward[properties[i]] === "object") {
            result += OctopusHunt.countRewards(reward[properties[i]]);
        } else {
            result += 1;
        }
    }
    return result;
};

OctopusHunt.VISIBLE_MARKS = [4, 6, 8];
OctopusHunt.BONUS_ROUNDS = [0, 1, 5, 9, 13, 17, 21, 25, 29];
OctopusHunt.STAGES = Array.apply(null, Array(30)).map(function (value, index) {
    return OctopusHunt.BONUS_ROUNDS.indexOf(index) !== -1;
});
OctopusHunt.REWARD_POOL = [
    { soft: 10 },
    { soft: 25 },
    { soft: 50 },
    { soft: 75 },
    { soft: 100 },
    { lives: 1, probability: 3 },
    { unlimitedLives: "1 hour", probability: 3 },
    { unlimitedLives: "3 hours", probability: 3 },
    { unlimitedLives: "6 hours" },
    { boosters: { cheap: 1 }, probability: 4 },
    { boosters: { medium: 1 }, probability: 4 },
    { boosters: { expensive: 1 }, probability: 2 }
];
OctopusHunt.PROLONGATION_PRICE = 100;