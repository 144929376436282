/**
 * Created by Reda on 30.10.2024
 */

var OctopusHuntBarrelsGrid = cc.Node.extend({
    ctor: function (barrels) {
        this._super();
        this.barrels = barrels;
        this.setContentSize2(cleverapps.styles.OctopusHuntBarrelsGrid.size);
        this.createRows();
        this.updatePosition();
    },
    createRows: function () {
        var styles = cleverapps.styles.OctopusHuntBarrelsGrid;
        var barrels = this.barrels;
        var rows = this.rows = [
            new cleverapps.Layout(barrels.slice(0, Math.ceil(barrels.length / 2)), {
                margin: styles.margin,
                direction: cleverapps.UI.HORIZONTAL,
                padding: {
                    right: styles.padding
                }
            }),
            new cleverapps.Layout(barrels.slice(Math.ceil(barrels.length / 2)), {
                margin: styles.margin,
                padding: {
                    left: styles.padding
                },
                direction: cleverapps.UI.HORIZONTAL
            })
        ];
        for (var i = 0; i < rows.length; i++) {
            this.addChild(rows[i]);
            rows[i].setPositionRound(styles.rows[i]);
            rows[i].setPositionRound(styles.rows[i]);
        }
    },
    updatePosition: function () {
        var styles = cleverapps.styles.OctopusHuntBarrelsGrid;
        var rows = this.rows;
        rows[0].setOptions({
            padding: {
                right: cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? 0 : styles.padding
            }
        });
        rows[1].setOptions({
            padding: {
                left: cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? 0 : styles.padding
            }
        });
        this.setScale(Math.min(1, cleverapps.scenes.getRunningScene().width / this.width));
    },

    showAnimation: function () {
        this.setVisible(true);
        this.barrels.forEach(function (barrel) {
            barrel.setAnimation(barrel.octopus ? "surface_octopus" : "surface_close");
        });
    },

    hideAnimation: function () {
        this.barrels.forEach(function (barrel) {
            barrel.setAnimation(barrel.octopus ? "submerge_oсtopus" : "submerge_close");
        });
    }
});

cleverapps.styles.OctopusHuntBarrelsGrid = {
    size: {
        width: 900,
        height: 540
    },
    margin: 250,
    padding: 280,
    rows: [
        {
            x: {
                align: "center",
                dx: 0
            },
            y: {
                align: "top"
            }
        },
        {
            x: {
                align: "center",
                dx: 0
            },
            y: {
                align: "bottom"
            }
        }
    ]
};