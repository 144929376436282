/**
 * Created by Reda on 14.10.2024
 */

var OctopusHuntGame = function (octopusHunt) {
    cleverapps.EventEmitter.call(this);
    this.octopusHunt = octopusHunt;
    this.barrels = [];
    for (var i = 0; i < OctopusHuntGame.NUMBER_OF_BARRELS; i++) {
        this.barrels.push(new OctopusHuntBarrel(this));
    }
};

OctopusHuntGame.prototype = Object.create(cleverapps.EventEmitter.prototype);
OctopusHuntGame.prototype.constructor = OctopusHuntGame;

OctopusHuntGame.prototype.generateRewards = function () {
    var availableRewards = this.octopusHunt.createRewardPool();
    var rewards = this.stageRewards = [];

    var octopus = this.octopusHunt.isBonusStage() ? 0 : 1;
    if (octopus) {
        rewards.push({
            octopus: true
        });
    }

    for (var i = 0; i < OctopusHuntGame.NUMBER_OF_BARRELS - octopus; i++) {
        var chosen = cleverapps.Random.random(0, availableRewards.length - 1);
        var choseReward = availableRewards[chosen];

        availableRewards = availableRewards.filter(function (reward) {
            return reward.id !== choseReward.id;
        });
        delete choseReward.id;

        rewards.push(choseReward);
    }
};

OctopusHuntGame.prototype.startRound = function () {
    if (this.octopusHunt.isLost()) {
        this.octopusHunt.endGame();
    } else {
        this.showRewards();
    }
};

OctopusHuntGame.prototype.showRewards = function () {
    this.generateRewards();

    cleverapps.focusManager.displayWhenFreeFocus({
        focus: "OctopusShowRewards",
        control: ["octopusHuntFooter", "barrelsGrid"],
        action: function (f) {
            for (var i = 0; i < this.stageRewards.length; i++) {
                this.barrels[i].showReward(this.stageRewards[i]);
            }
            cleverapps.timeouts.setTimeout(f, 4200);
        }.bind(this)
    });
};

OctopusHuntGame.prototype.isRoundRunning = function () {
    return this.stageRewards;
};

OctopusHuntGame.prototype.chooseRandomReward = function () {
    var reward = cleverapps.Random.choose(this.stageRewards);

    if (reward.octopus) {
        this.octopusHunt.handleOctopus();
    }

    return reward;
};

OctopusHuntGame.prototype.submerge = function () {
    if (this.octopusHunt.getCurrentStage() < OctopusHunt.STAGES.length) {
        if (!this.octopusHunt.isLost()) {
            cleverapps.audio.playSound(bundles.octopus_hunt.urls.barrel_submerge_effect);
        }
        this.trigger("submerge");
    }
};

OctopusHuntGame.prototype.receiveStageRewards = function (reward) {
    this.stageRewards = undefined;
    this.octopusHunt.addReward(reward);
};

OctopusHuntGame.prototype.endRound = function (reward, callback) {
    if (reward.octopus) {
        this.stageRewards = this.stageRewards.filter(function (reward) {
            return !reward.octopus;
        });

        this.octopusHunt.trigger("loseRound");
        return;
    }

    Mission.logEvent(cleverapps.EVENTS.MISSION_ROUND, this.octopusHunt.mission.getName(), {
        index: this.octopusHunt.getCurrentStage()
    });

    cleverapps.focusManager.compound(callback, [function (f) {
        this.barrels.forEach(function (barrel) {
            if (barrel.opened) {
                barrel.close();
            }
        });
        cleverapps.timeouts.setTimeout(f, 500);
    }.bind(this),

    function (f) {
        f();
        this.octopusHunt.toNextStage();
        if (!this.octopusHunt.isFinished()) {
            this.startRound();
        }
    }.bind(this)]);
};

OctopusHuntGame.NUMBER_OF_BARRELS = 4;
OctopusHuntGame.STAGE_TYPES = {};
OctopusHuntGame.STAGE_TYPES.PREVIOUS = 0;
OctopusHuntGame.STAGE_TYPES.DEFAULT = 1;
OctopusHuntGame.STAGE_TYPES.BONUS = 2;