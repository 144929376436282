/**
 * Created by Reda on 13.10.2024
 */

var OctopusHuntScene = cleverapps.FixedWidthScene.extend({
    ctor: function (mission) {
        this._super();

        this.mission = mission;

        var octopusHunt = this.octopusHunt = this.mission.logic.octopusHunt;
        this.octopusHuntGame = new OctopusHuntGame(octopusHunt);

        octopusHunt.on("updateState", this.createListener(this.updateState.bind(this)), this);
        octopusHunt.on("updateRewards", this.createListener(this.updateRewards.bind(this)), this);
        octopusHunt.on("endGame", this.createListener(this.missionOverActions.bind(this)), this);
        octopusHunt.on("loseRound", this.createListener(this.loseRound.bind(this)), this);
    },

    onSceneLoaded: function () {
        var styles = cleverapps.styles.OctopusHuntScene;

        this._super(cleverapps.Environment.SCENE_OCTOPUS_HUNT);

        this.footer = new OctopusHuntFooter(this.octopusHunt);
        this.addChild(this.footer, 3);
        this.footer.setPositionRound(styles.footer);

        var footerControl = new HidingNode(this.footer, cleverapps.UI.VERTICAL);
        cleverapps.focusManager.registerControl("octopusHuntFooter", footerControl);

        cleverapps.focusManager.showControlsWhileFocused("barrelsGrid");

        this.createBarrelGrid();

        this.octopusHuntPath = new OctopusHuntPath(this, this.octopusHunt);
        this.addChild(this.octopusHuntPath, 1);
        this.octopusHuntPath.setPositionRound(styles.path.position);

        this.createMaxStagesIndicator();

        Mission.logEvent(cleverapps.EVENTS.MISSION_OPEN, this.mission.getName());
    },

    playIntroActions: function (f) {
        f();
        this.octopusHuntGame.startRound();
    },

    createMaxStagesIndicator: function () {
        var styles = cleverapps.styles.OctopusHuntScene;
        var background = this.indicator = new cc.Sprite(bundles.octopus_hunt.frames.mark_number_background_png);
        background.setRotation(-90);
        var text = cleverapps.UI.generateOnlyText(OctopusHunt.STAGES.length, cleverapps.styles.FONTS.WHITE_TEXT);
        background.addChild(text);
        text.setPositionRound(styles.indicator.textPosition);
        text.setRotation(90);
        this.addChild(background);
        background.setPositionRound(styles.indicator);
    },

    createBarrelGrid: function () {
        var styles = cleverapps.styles.OctopusHuntScene;
        var barrels = [];
        for (var i = 0; i < this.octopusHuntGame.barrels.length; i++) {
            barrels.push(new OctopusHuntBarrelView(this.octopusHuntGame.barrels[i], this.footer));
        }
        var barrelsGrid = this.barrelsGrid = new OctopusHuntBarrelsGrid(barrels);
        this.addChild(barrelsGrid, 2);
        barrelsGrid.setAnchorPoint(0.5, 0.5);
        barrelsGrid.setPositionRound(styles.barrelsGrid.position);

        cleverapps.focusManager.registerControl("barrelsGrid", new HidingNode(barrelsGrid, cleverapps.UI.VERTICAL));
    },

    missionOverActions: function () {
        new ActionPlayer([
            function (f) {
                var rewards = this.octopusHunt.rewards;
                if (Object.keys(rewards).length > 0 && !this.octopusHunt.isLost()) {
                    cleverapps.focusManager.display({
                        focus: "octopusHuntRewardWindow",
                        stack: true,
                        action: function (focusCallback) {
                            Mission.logEvent(cleverapps.EVENTS.MISSION_REWARD_COST, this.mission.getName(), {
                                value: RewardsList.calcCost(rewards)
                            });
                            Mission.logEvent(cleverapps.EVENTS.MISSION_REWARD, this.mission.getName());
                            new RewardWindow(rewards, { event: cleverapps.EVENTS.EARN.MISSION });
                            cleverapps.focusManager.onceNoWindowsListener = function () {
                                focusCallback();
                                f();
                            };
                        }.bind(this)
                    });
                } else {
                    f();
                }
            }.bind(this),
            function (f) {
                this.closeAction();
                f();
            }.bind(this)
        ]).play();
    },

    switchIndicator: function () {
        this.indicator && this.indicator.setVisible(OctopusHunt.STAGES.length - this.octopusHunt.getCurrentStage() >= OctopusHunt.VISIBLE_MARKS[cleverapps.resolution.mode]);
    },

    updateRewards: function () {
        if (this.footer) {
            this.footer.updateState();
        }
    },

    updateState: function () {
        this.updateRewards();
        if (this.octopusHuntPath && !this.octopusHunt.isFinished()) {
            this.octopusHuntPath.toNextStage();
        }
        this.switchIndicator();
    },

    openGuideWindow: function () {
        cleverapps.focusManager.display({
            focus: "OctopusHuntGuideWindow",
            action: function (f) {
                new GuideWindow({
                    name: "OctopusHuntGuideWindow",
                    bundle: bundles.octopus_hunt_guide
                });
                cleverapps.focusManager.onceNoWindowsListener = f;
            }
        });
    },

    loseRound: function () {
        cleverapps.focusManager.display({
            focus: "OctopusProlongationWindow",
            control: ["MenuBarGoldItem", "MenuBarCoinsItem"],
            stack: true,
            action: function (f) {
                var prolongationWindow = new ProlongationWindow(this.octopusHunt.prolongation, {
                    type: Prolongation.TYPES.OCTOPUS_HUNT,
                    offer: {
                        type: Prolongation.TYPES.OCTOPUS_HUNT,
                        rewards: this.octopusHunt.rewards,
                        stagesLeft: this.octopusHunt.calcStagesBeforeBonus(),
                        price: OctopusHunt.PROLONGATION_PRICE,
                        text: " $$ " + OctopusHunt.PROLONGATION_PRICE,
                        priceType: "currency"
                    },
                    onSuccess: function () {
                        f();
                        Mission.logEvent(cleverapps.EVENTS.SPENT.HARD, this.mission.getName() + "_", {
                            value: OctopusHunt.PROLONGATION_PRICE
                        });
                        Mission.logEvent(cleverapps.EVENTS.MISSION_PROLONGATION, this.mission.getName());
                        this.octopusHunt.prolong();
                    }.bind(this),
                    onGiveUp: function () {
                        f();
                        this.octopusHunt.endGame();
                    }.bind(this)
                });
                this.addTentacles(prolongationWindow);
            }.bind(this)
        });
    },

    addTentacles: function (prolongationWindow) {
        var styles = cleverapps.styles.OctopusHuntScene.tentacles;

        for (var index = 1; index <= 4; index++) {
            var tentacle = new cleverapps.Spine(bundles.octopus_hunt.jsons["tentacle_" + index + "_json"]);
            tentacle.setAnimationAndIdleAfter("open", "animation");
            prolongationWindow.window.addChild(tentacle, 100);
            tentacle.setPositionRound(styles["tentacle_" + index]);
        }

        this.runAction(new cc.Sequence(
            new cc.DelayTime(350),
            new cc.CallFunc(function () {
                cleverapps.audio.playSound(bundles.octopus_hunt.urls.tentacles_effect);
            })
        ));
    },

    listBundles: function () {
        return ["octopus_hunt", "octopus_hunt_bg", "game"];
    },

    getBackgroundStyles: function () {
        return {
            bundle: "octopus_hunt_bg",
            backgroundId: "background"
        };
    },

    scaleGameField: function () {
        if (this.octopusHuntPath) {
            this.octopusHuntPath.y = this.height * 3 / 4 - this.octopusHuntPath.height / 2;
            if (!this.octopusHunt.isFinished()) {
                this.octopusHuntPath.updatePosition();
            }
        }
        this.switchIndicator();
        this.barrelsGrid.updatePosition();
    }
});

cleverapps.styles.OctopusHuntScene = {
    barrelsGrid: {
        position: [
            {
                x: { align: "center", dx: 0 },
                y: { align: "center", dy: -80 }
            },
            {
                x: { align: "center", dx: 0 },
                y: { align: "center", dy: -110 }
            },
            {
                x: { align: "center", dx: 0 },
                y: { align: "center", dy: -110 }
            }
        ]
    },
    footer: [
        {
            x: { align: "center", dx: 0 },
            y: { align: "bottom", dy: 170 }
        },
        {
            x: { align: "center", dx: 0 },
            y: { align: "bottom", dy: 110 }
        },
        {
            x: { align: "center", dx: -140 },
            y: { align: "bottom", dy: 110 }
        }
    ],
    path: {
        position: {
            x: { align: "left", dx: 70 },
            y: { align: "top", dy: -260 }
        }
    },
    indicator: {
        x: { align: "right", dx: -10 },
        y: { align: "top", dy: -140 },
        textPosition: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 4 }
        }
    },
    x: { align: "center" },
    y: { align: "center" },
    scenePadding: 100,
    tentacles: {
        tentacle_1: {
            x: { align: "left", dx: -30 },
            y: { align: "bottom", dy: -60 }
        },

        tentacle_2: {
            x: { align: "left", dx: -77 },
            y: { align: "center", dy: 30 }
        },

        tentacle_3: {
            x: { align: "right", dx: 64 },
            y: { align: "center", dy: 160 }
        },

        tentacle_4: {
            x: { align: "right", dx: -90 },
            y: { align: "top", dy: 71 }
        }
    }
};