/**
 * Created by Reda on 14.10.2024
 */

var OctopusHuntBarrelView = cc.Node.extend({
    ctor: function (octopusHuntBarrel, rewardsTarget) {
        this._super();

        this.octopusHuntBarrel = octopusHuntBarrel;
        this.rewardsTarget = rewardsTarget;

        this.createBarrel();

        octopusHuntBarrel.on("showReward", this.showReward.bind(this));
        octopusHuntBarrel.on("collectReward", this.collectReward.bind(this));
        octopusHuntBarrel.on("submerge", this.submerge.bind(this));
        octopusHuntBarrel.on("close", this.close.bind(this));
    },

    setAnimation: function (animation, idle, repeat) {
        if (!idle) {
            this.barrel.setAnimation(0, animation, repeat);
            this.waves.setAnimation(0, animation, repeat);
        } else {
            this.barrel.setAnimationAndIdleAfter(animation, idle);
            this.waves.setAnimationAndIdleAfter(animation, idle);
        }
    },

    submerge: function () {
        this.setAnimation("submerge_close");
    },

    createBarrel: function () {
        var barrel = this.barrel = new cleverapps.Spine(bundles.octopus_hunt.jsons.barrel_json);
        this.addChild(barrel);
        barrel.setAnchorPoint(0.5, 0.5);
        barrel.setPositionRound(cleverapps.styles.OctopusHuntBarrel);
        this.setContentSize2(barrel.getContentSize());

        var waves = this.waves = new cleverapps.Spine(bundles.octopus_hunt.jsons.waves_json);
        this.addChild(waves);
        waves.setAnchorPoint(0.5, 0.5);
        waves.setPositionRound(cleverapps.styles.OctopusHuntBarrel);

        cleverapps.UI.onClick(barrel, function () {
            if (this.blocked) {
                return;
            }

            this.octopusHuntBarrel.onClick();
        }.bind(this), {
            interactiveScale: true
        });

        cleverapps.UI.applyHover(barrel, {
            onMouseOver: function () {
                if (this.blocked) {
                    return;
                }

                AnimationsLibrary.run("outline", barrel, { animationName: "idle_close" });
            }.bind(this),
            onMouseOut: function () {
                this.removeOutline();
            }.bind(this)
        });
    },

    removeOutline: function () {
        if (!this.barrel.outlineSprite) {
            return;
        }

        this.barrel.outlineSprite.removeFromParent();
        this.barrel.outlineSprite = undefined;
    },

    displayReward: function (reward) {
        var animationName = reward ? "open_barrel" : "open_barrel_octopus";
        var rewardDelay = 0.5;

        this.setAnimation(animationName);
        cleverapps.audio.playSound(bundles.octopus_hunt.urls.barrel_surface_effect);

        if (reward) {
            reward.runAction(new cc.Sequence(
                new cc.DelayTime(rewardDelay),
                AnimationsLibrary.showUp(reward, { fallOffset: cleverapps.styles.OctopusHuntBarrel.animationOffset, fallDuration: 0.15 }),
                new cc.DelayTime(1 - rewardDelay),
                AnimationsLibrary.disappear(reward),
                new cc.RemoveSelf()
            ));
        } else {
            cleverapps.audio.playSound(bundles.octopus_hunt.urls.octopus_shoot_effect);
        }
    },

    mixAnimation: function () {
        var styles = cleverapps.styles.OctopusHuntBarrel;
        var delay = 0.3 + Math.random() * 0.05;

        var scene = cleverapps.scenes.getRunningScene();
        var centerPoint = this.parent.convertToNodeSpace(cc.p(scene.width / 2, scene.height / 2));

        this.setAnimation("jump");

        this.runAction(new cc.Spawn(
            new cc.Sequence(
                new cc.DelayTime(delay),
                new cc.JumpTo(0.225, centerPoint, styles.rewardIcon.jumpHeight, 1).easing(cc.easeInOut(1.2))
            ),
            new cc.CallFunc(function () {
                this.setAnimation("submerge_close");
                cleverapps.audio.playSound(bundles.octopus_hunt.urls.barrel_submerge_effect);
            }.bind(this))
        ));
    },

    showRewardAnimation: function (reward) {
        var currentPosition = this.getPosition();

        new ActionPlayer([
            function (f) {
                this.displayReward(reward);
                cleverapps.timeouts.setTimeout(f, 1900);
            }.bind(this),

            function (f) {
                this.close();
                this.barrel.setCompleteListener(f);
            }.bind(this),

            function (f) {
                this.mixAnimation();
                cleverapps.timeouts.setTimeout(f, 1000);
            }.bind(this),

            function (f) {
                this.setPosition(currentPosition);

                this.setAnimation("surface_close", "idle_close");
                cleverapps.audio.playSound(bundles.octopus_hunt.urls.barrel_surface_effect);

                cleverapps.timeouts.setTimeout(f, 800);
            }.bind(this),

            function (f) {
                this.blocked = false;
                f();
            }.bind(this)
        ]).play();
    },

    showReward: function (reward) {
        var rewardIcon = undefined;
        if (reward) {
            var styles = cleverapps.styles.OctopusHuntBarrel;
            rewardIcon = new RewardsListComponent(new RewardsList(reward), {
                font: cleverapps.styles.FONTS.OCTOPUS_REWARDS_TEXT,
                fitToBox: styles.size,
                textDirection: cleverapps.UI.HORIZONTAL,
                noShowControls: true
            });
            this.addChild(rewardIcon);
            rewardIcon.setVisible(false);
            rewardIcon.setPositionRound(cleverapps.styles.OctopusHuntBarrel.rewardIcon);
        }
        this.showRewardAnimation(rewardIcon);
    },

    collectContent: function (reward, content) {
        this.blocked = true;
        this.removeOutline();

        cleverapps.focusManager.display({
            focus: "OctopusCollectReward",
            control: ["octopusHuntFooter", "barrelsGrid"],
            actions: [
                function (f) {
                    if (content) {
                        this.setAnimation("open_barrel", "idle_open");
                        cleverapps.audio.playSound(bundles.octopus_hunt.urls.barrel_open_effect);
                        content.runAction(new cc.Sequence(
                            new cc.DelayTime(0.6),
                            AnimationsLibrary.showUp(content, { fallOffset: cleverapps.styles.OctopusHuntBarrel.animationOffset, fallDuration: 0.15 }),
                            new cc.CallFunc(f)
                        ));
                    } else {
                        this.octopus = true;
                        this.setAnimation("open_barrel_octopus");
                        this.barrel.setCompleteListener(f);
                        cleverapps.audio.playSound(bundles.octopus_hunt.urls.octopus_shoot_effect);
                    }
                }.bind(this),

                function (f) {
                    this.setAnimation(content ? "idle_open" : "idle_octopus", undefined, true);

                    if (content) {
                        content.runAction(content.receiveRewardsAction({
                            callback: function () {
                                f();
                                cleverapps.audio.playSound(bundles.octopus_hunt.urls.collect_reward_effect);
                                var rewardObject = content.reward.rewards[0];
                                var amount = rewardObject.type === "unlimitedLives" ? rewardObject.value.title : rewardObject.getAmount();

                                this.rewardsTarget.runAction(new cc.Sequence(
                                    AnimationsLibrary.animateDelta("+" + amount + " **", {
                                        target: this.rewardsTarget.getRewardTarget(reward),
                                        icons: {
                                            "**": rewardObject.getIcon()
                                        },
                                        font: cleverapps.styles.FONTS.SCENE_ANIMATE_DELTA_TEXT
                                    }),
                                    new cc.CallFunc(function () {
                                        this.octopusHuntBarrel.onCollect(reward);
                                    }.bind(this))
                                ));
                            }.bind(this)
                        }));
                    } else {
                        this.barrel.runAction(new cc.Sequence(
                            new cc.DelayTime(0.5),
                            new cc.CallFunc(f)
                        ));
                    }
                }.bind(this),

                function (f) {
                    if (!content) {
                        f();
                        this.octopusHuntBarrel.endRound(reward);
                        return;
                    }

                    this.octopusHuntBarrel.endRound(reward, f);
                }.bind(this)
            ]
        });
    },

    collectReward: function (reward) {
        var rewardIcon = undefined;
        if (reward && !reward.octopus) {
            var styles = cleverapps.styles.OctopusHuntBarrel;
            rewardIcon = new RewardsListComponent(new RewardsList(reward, {
                target: this.rewardsTarget.getRewardTarget(reward),
                withoutDelta: true
            }), {
                fitToBox: styles.size,
                font: cleverapps.styles.FONTS.OCTOPUS_REWARDS_TEXT,
                textDirection: cleverapps.UI.HORIZONTAL,
                noShowControls: true
            });
            this.addChild(rewardIcon);
            rewardIcon.setVisible(false);
            rewardIcon.setPositionRound(cleverapps.styles.OctopusHuntBarrel.collectRewardIcon);
        }
        this.collectContent(reward, rewardIcon);
    },

    close: function () {
        this.setAnimation(this.octopus ? "close_barrel_octopus" : "close_barrel");
        this.octopus = false;

        cleverapps.audio.playSound(bundles.octopus_hunt.urls.barrel_close_effect);
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    OCTOPUS_REWARDS_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.styles.OctopusHuntBarrel = {
    x: { align: "center" },
    y: { align: "center" },
    rewardIcon: {
        x: { align: "center", dx: 30 },
        y: { align: "top", dy: 110 },
        moveByY: 70,
        jumpHeight: 100
    },
    collectRewardIcon: {
        x: { align: "center", dx: 30 },
        y: { align: "top", dy: 80 },
        moveByY: 30
    },
    size: {
        width: 170,
        height: 170
    },
    animationOffset: 55
};